import { AxiosResponse } from "axios";
import Axios from "./axios";

class Price {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    indexDstv(): Promise<AxiosResponse> {
        return this.axios.get("/prices/dstv");
    }

    indexCanalplus(): Promise<AxiosResponse> {
        return this.axios.get("/prices/canalplus");
    }
}

export default new Price(Axios);
